import type { RecommendationList } from '@apptus/esales-api';
import { defineStore, acceptHMRUpdate } from 'pinia';

interface State {
    path?: string
    isLoading: boolean
    lists: RecommendationList[]
}

export const useProductRecommendations = defineStore('product-recommendations', {
    state: (): State => ({
        path: undefined,
        isLoading: false,
        lists: [],
    }),

    actions: {
        async register(path: string) {
            if (this.isLoading || this.path === path) {
                return;
            }

            this.$patch({
                path,
                lists: [],
                isLoading: true,
            });

            await this.update();
        },
        async update() {
            if (!this.path) {
                return;
            }

            const { getLandingPage } = useElevate();
            let { data } = await getLandingPage(this.path);
            // TMP: This broke somehow and the initial request returns null on CSR even though it shouldn't with the await and all. Re-doing the request gets us the data we need if the first request somehow fails. Worked @ commit 085440a8, broken @ f437bbfc. Probably related to the `vue` package update we did (3.5.8 -> 3.5.11), but can't revert because the earlier package version had problems too. Implemented a temporary fix here instead of in useElevate to make sure not to alter and break it again.
            if (data.value === null) {
                const x = await getLandingPage(this.path);
                data = x.data;
            }

            this.$patch({
                lists: data.value?.recommendationLists || [],
                isLoading: false,
            });
        },
    },

    getters: {
        getPlacement: (state) => (name: string) => state.lists.find((item) => item.id === name),
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useProductRecommendations, import.meta.hot));
}
