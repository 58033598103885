<template>
    <div ref="target"
        class="lg:relative lg:mr-2 lg:min-h-11">
        <v-header-button type="button"
            class="lg:hidden"
            :aria-label="t('search-label')"
            @click="isOpen = true">
            <v-icon icon="search"
                class="text-2xl text-[color:var(--color)]"
                :style="{
                    '--color': fontColor
                }"
            />
        </v-header-button>

        <div class="fixed inset-x-5 top-5 z-30 flex min-w-80 space-x-5 transition-opacity duration-500 lg:absolute
            lg:left-auto lg:right-0 lg:top-0 lg:transition-none"
            :class="{
                'translate-y-[-100vh] opacity-0 lg:translate-y-0 lg:opacity-100': !isOpen,
                'opacity-100': isOpen,
            }">
            <label class="flex h-11 flex-1 items-center
                justify-between rounded-full border bg-white pl-4 md:space-x-4 lg:transition-all lg:duration-300"
                :class="{
                    'border-gray-200 lg:w-[560px]': isOpen,
                    'border-transparent lg:w-80': !isOpen,
                }">
                <v-icon icon="search"
                    class="hidden text-2xl text-black md:block" />

                <div class="relative h-full flex-1"
                    :class="{
                        'pr-0': !isOpen,
                        'pr-32': isOpen,
                    }">
                    <input ref="input"
                        v-model="query"
                        autocomplete="off"
                        type="text"
                        :placeholder="placeholder"
                        class="size-full bg-transparent text-black outline-none"
                        @input="origin = 'ORGANIC'"
                        @focusin="isOpen = true"
                        @keyup.enter.prevent="onEnterPress">

                    <transition
                        enter-active-class="transition duration-300"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition duration-100"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <nuxt-link v-if="isOpen && productCount > 0"
                            :to="{ path: '/search', query: { query, origin } }"
                            class="absolute inset-y-1 right-1 hidden items-center rounded-full
                                bg-black px-4 py-1 font-prenton-cond text-sm uppercase tracking-4xl text-white
                                hover:bg-gray-800 focus:bg-gray-800 active:bg-gray-700 lg:inline-flex">
                            {{ t('loader.show-more') }}
                        </nuxt-link>
                    </transition>
                </div>
            </label>

            <button type="button"
                class="text-2xl text-gray-800 hover:text-gray-600 focus:text-gray-600 active:text-gray-400 lg:hidden"
                :aria-label="t('close-search')"
                @click="close">
                <v-icon icon="close" />
            </button>

            <button v-show="productCount > 0"
                type="button"
                class="text-2xl text-gray-800 hover:text-gray-600 focus:text-gray-600 active:text-gray-400 lg:hidden"
                :aria-label="t('loader.show-more')"
                @click="onEnterPress">
                <v-icon icon="arrow-right" />
            </button>
        </div>

        <client-only>
            <transition-group
                enter-active-class="transition duration-300"
                enter-from-class="opacity-0 -translate-y-20 lg:translate-y-0"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition duration-300"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-20 lg:translate-y-0"
            >
                <template v-if="isOpen">
                    <!-- eslint-disable-next-line tailwindcss/enforces-shorthand -->
                    <div class="fixed left-0 top-0 z-[28] h-screen w-screen bg-black/30"
                        tabindex="0"
                        role="button"
                        :aria-label="t('close-search')"
                        @keypress="close"
                        @click="close">
                    </div>

                    <div class="fixed inset-x-0 top-0 z-[29] bg-white pt-20 shadow-xl
                        lg:absolute lg:-right-2 lg:-top-3 lg:left-auto lg:w-[576px]
                        lg:rounded lg:transition-all lg:duration-300">
                        <div class="max-h-[calc(100svh-8rem)] space-y-4 overflow-auto pb-sm
                            lg:max-h-[calc(100vh-13rem)]">
                            <div class="space-y-lg">
                                <div v-if="recentSuggestions.length > 0"
                                    class="flex flex-col space-y-2 px-lg ">
                                    <p class="text-sm text-gray-500">
                                        {{ t('search-modal-recents') }}
                                    </p>

                                    <!-- eslint-disable vue/no-v-html -->
                                    <button v-for="(item, index) in recentSuggestions"
                                        :key="index"
                                        type="button"
                                        class="cursor-pointer text-left font-bold text-black hover:underline"
                                        @click="useAutocomplete(item.q, 'RECENT_SEARCH')">
                                        {{ item.q }}
                                    </button>
                                    <!-- eslint-enable vue/no-v-html -->
                                </div>

                                <div v-if="autocompleteSuggestions.length > 0"
                                    class="flex flex-col space-y-2 px-lg ">
                                    <p class="text-sm text-gray-500">
                                        {{ t('search-modal-results') }}
                                    </p>

                                    <!-- eslint-disable vue/no-v-html -->
                                    <button v-for="(item, index) in autocompleteSuggestions.slice(0, 3)"
                                        :key="index"
                                        type="button"
                                        class="cursor-pointer text-left font-bold text-black hover:underline"
                                        @click="useAutocomplete(item.q, 'PHRASE_SUGGEST')"
                                        v-html="getHighlightedText(item.highlighted)">
                                    </button>
                                    <!-- eslint-enable vue/no-v-html -->
                                </div>

                                <template v-if="query.length > 0">
                                    <div v-if="isLoading"
                                        class="mb-md flex w-full flex-row justify-center">
                                        <span class="my-md text-6xl text-black">
                                            <v-icon class="animate-spin"
                                                icon="spinner"></v-icon>
                                        </span>
                                    </div>

                                    <div v-if="productCount > 0">
                                        <p class="mx-lg mb-sm text-sm text-gray-500">
                                            {{ t('showing', { count: products.length, total: productCount }) }}
                                        </p>

                                        <div v-if="products.length > 0"
                                            class="grid grid-cols-2 gap-xs px-sm md:grid-cols-3">
                                            <v-search-product-card v-for="item in products"
                                                :key="item.key"
                                                :product="item"
                                                @click="close"
                                            />
                                        </div>
                                    </div>

                                    <div v-else>
                                        <p class="px-lg text-sm">
                                            {{ t('search-modal-no-results') }}: <span>"{{ query }}"</span>
                                        </p>
                                    </div>

                                    <div v-if="productCount > products.length"
                                        class="col-span-3 flex px-sm">
                                        <v-button theme="black"
                                            class="flex-1 text-center"
                                            :to="{ path: '/search', query: { query, origin } }"
                                            @click="close">
                                            {{ t('loader.show-more') }}
                                        </v-button>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </transition-group>
        </client-only>
    </div>
</template>

<script lang="ts" setup>
import type { PhraseSuggestion, Product, RecentSearch } from '@apptus/esales-api';
import { useScrollLock, watchDebounced, useFocusWithin } from '@vueuse/core';

const properties = defineProps<{
    placeholder?: string
    fontColor?: string
}>();

const { t } = useI18n();

const isOpen = ref(false);
const input = ref<HTMLInputElement>();
const query = ref('');
const origin = ref('ORGANIC');
const isLoading = ref(false);
const products = ref<Product[]>([]);
const productCount = ref(0);
const autocompleteSuggestions = ref<PhraseSuggestion[]>([]);
const recentSuggestions = ref<RecentSearch[]>([]);
const { getAutocompleteSuggestions } = useElevate();
const isLocked = process.server ? ref(false) : useScrollLock(document.querySelector('body'));

const fontColor = computed<string | undefined>(() => (properties.fontColor === undefined
    ? '#FFF'
    : properties.fontColor));

async function fetchSearchResults() {
    isLoading.value = true;

    let { data } = await getAutocompleteSuggestions(query.value);
    // TMP: This broke somehow and the initial request returns null on CSR even though it shouldn't with the await and all. Re-doing the request gets us the data we need if the first request somehow fails. Worked @ commit 085440a8, broken @ f437bbfc. Probably related to the `vue` package update we did (3.5.8 -> 3.5.11), but can't revert because the earlier package version had problems too. Implemented a temporary fix here instead of in useElevate to make sure not to alter and break it again.
    if (data.value === null) {
        const x = await getAutocompleteSuggestions(query.value);
        data = x.data;
    }

    products.value = data.value?.productSuggestions.flatMap((group) => group.products) || [];
    productCount.value = data.value?.totalHits || 0;
    autocompleteSuggestions.value = (data.value?.phraseSuggestions || [])
        .filter((suggestion) => suggestion.q !== query.value);
    recentSuggestions.value = data.value?.recentSearches || [];

    isLoading.value = false;
}

const target = ref<HTMLInputElement>();
const { focused } = useFocusWithin(target);

function getHighlightedText(text: string) {
    return text
        .replaceAll('{', '<span class="font-normal">')
        .replaceAll('}', '</span>');
}

function useAutocomplete(value: string, type: string) {
    query.value = value;
    origin.value = type;
    input.value?.focus();
}

watchDebounced(query, async () => {
    await fetchSearchResults();
}, {
    debounce: 300,
});

watch(isOpen, (value) => {
    if (value) {
        fetchSearchResults();
        input.value?.focus();
        isLocked.value = true;
    } else {
        isLocked.value = false;
    }
});

watchDebounced(focused, (isFocused) => {
    if (isFocused) {
        return;
    }
    isOpen.value = false;
}, { debounce: 50 });

function close() {
    input.value?.blur();
    isOpen.value = false;
}

const router = useRouter();

function onEnterPress() {
    close();

    router.push({
        path: '/search',
        query: {
            query: query.value,
            origin: origin.value,
        },
    });

    query.value = '';
}
</script>

<i18n lang="json">
{
    "et": {
        "search-modal-recents": "Varasemad otsingud",
        "search-modal-results": "Seotud otsingutulemused",
        "search-modal-no-results": "Tulemusi ei leitud",
        "search-label": "Otsi",
        "close-search": "Sulge otsing",
        "loader": {
            "show-more": "Näita kõiki"
        },
        "showing": "Näidatakse {count} / {total} tk"
    },
    "fi": {
        "search-modal-recents": "Aiemmat hakusi",
        "search-modal-results": "Osuvimmat hakutulokset",
        "search-modal-no-results": "Ei hakutuloksia hakusanalla",
        "search-label": "Hae",
        "close-search": "Sulje haku",
        "loader": {
            "show-more": "Näytä kaikki"
        },
        "showing": "Näytetään {count} / {total} kpl"
    }
}
</i18n>
